<template>
    <div>
        <div class="mb-2"><label class="font-weight-bold">Property Details</label></div>
        <label class="custom-control custom-checkbox">
            <input type="checkbox" name="is_waterfront" class="custom-control-input" v-model="is_waterfront">
            <span class="custom-control-label">Waterfront</span>
        </label>
        <label class="custom-control custom-checkbox">
            <input type="checkbox" name="is_waterview" class="custom-control-input" v-model="is_waterview">
            <span class="custom-control-label">Waterview</span>
        </label>
        <label class="custom-control custom-checkbox">
            <input type="checkbox" name="open_houses" class="custom-control-input" v-model="open_houses">
            <span for="open_houses" class="custom-control-label">Upcoming Open House</span>
        </label>
    </div>
</template>

<script>
    export default {
        props: {
            dataWaterfront: {
                type: String,
                default: ''
            },
            dataWaterview: {
                type: String,
                default: ''
            },
            dataOpenHouse: {
                type: String,
                default: ''
            },
        },

        data () {
            return {
                is_waterfront: this.dataWaterfront,
                is_waterview: this.dataWaterview,
                open_house: this.dataOpenHouse
            }
        }

    }
</script>