<template>
    <form class="search-bar" method="get" >
        <input type="hidden" name="q" value="search" >
        <div class="row">
            <div class="col-sm-6 col-sm-6 col-lg-3">
                <label class="pt-2 font-weight-bold">Keyword</label>
                <smart-field
                    :field-value="searchTerms.omni"
                    preview="Address, MLS, Area"
                    class="w-100"
                ></smart-field>
            </div>
            <div class="col-sm-6 col-lg-3">
                <label class="pt-2 font-weight-bold">City / Area</label>
                <area-field
                    :field-value="searchTerms.region"
                >
                </area-field>
            </div>
            <div class="col-sm-6 col-lg-3">
                <label class="pt-2 font-weight-bold">Property Type</label>
                <property-type
                    :field-value="searchTerms.property_type"
                >
                </property-type>
            </div>
            <div class="col-sm-6 col-lg-3 pt-4 row no-gutters">

                <button
                    @click="toggleAdvanced"
                    type="button"
                    class="btn btn-primary dropdown-toggle col-8 col-xl-auto mt-lg-3 px-3"
                    aria-haspopup="true"
                    aria-expanded="false"
                    >Advanced Options</button>
                <button type="submit" class="btn btn-danger col-4 col-xl-auto mt-lg-3 px-3">Search</button>
            </div>
        </div>
        <div v-if="advancedOpen" id="advanced-menu" class="advanced-menu row p-md-5">
            <div class="col-lg-6 col-xl-12">

                <div class="row">
                    <div class="col-6 col-xl-2">
                        <min-price-field
                            class="mb-4"
                            :field-value="searchTerms.minPrice"
                        >
                        </min-price-field>
                    </div>
                    <div class="col-6 col-xl-2">
                        <max-price-field
                            class="mb-4"
                            :field-value="searchTerms.maxPrice"
                        ></max-price-field>
                    </div>

                    <div class="col-6 col-xl-2">
                        <bedrooms-field
                            class="mb-4"
                            :field-value="searchTerms.beds"
                        ></bedrooms-field>
                    </div>
                    <div class="col-6 col-xl-2">
                        <bathrooms-field
                            class="mb-4"
                            :field-value="searchTerms.baths"
                        ></bathrooms-field>
                    </div>

                    <div class="col-6 col-xl-2">
                        <sqft-field
                            class="mb-4"
                            :field-value="searchTerms.sqft"
                        ></sqft-field>
                    </div>
                    <div class="col-6 col-xl-2">
                        <acreage-field
                            class="mb-4"
                            :field-value="searchTerms.acreage"
                        ></acreage-field>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-xl-12">

                <div class="row">
                    <div class="col-12 col-md-6 col-lg-12 col-xl-6">

                        <status-field
                            class="mb-6"
                            :search-terms="searchTerms.status"
                        ></status-field>

                    </div>
                    <div class="col-12 col-md-6 col-lg-12 col-xl-6">

                        <details-field
                            class="mb-6"
                            :data-waterfront="searchTerms.is_waterfront"
                            :data-waterview="searchTerms.is_waterview"
                            :data-foreclosures="searchTerms.open_houses"
                        ></details-field>

                    </div>
                </div>
            </div>
        </div>
        <input name="page" value="1" type="hidden" >
    </form>
</template>

<script>
    export default {
        props: {
            searchTerms: {
                type: Object,
                default: {}
            }
        },
        data(){
            return {
                advancedOpen: false,
            }
        },

        created(){
            this.advancedOpen = false;
        },

        methods: {
            toggleAdvanced(event){
                if (event) event.preventDefault();
                this.advancedOpen = !this.advancedOpen;
            },
        }
    }
</script>