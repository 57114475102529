<template>
    <div>
        <div class="row flex-grow-1 pt-4">
            <div class="col pb-4 px-4">
                <property-map
                    :lat="-85.30042"
                    :long="29.86622"
                    :zoom="9"
                    :pins="data"
                    @propertyClicked="openProperty"
                ></property-map>
            </div>
            <div
                v-if="selectedProperty != null"
                class="col-sm-auto pl-0 pb-4"
            >
                <div class="property-view h-100 ">
                    <map-listing
                        :mls="selectedProperty"
                        :themedir="themedir"
                    ></map-listing>
                    <div class="listing-controls p-4 text-center">
                        <a
                            class="btn btn-secondary rounded-pill mb-2"
                            :href="'/listing/' + selectedProperty + '/'"
                            target="_blank"
                        >Property Details</a>
                        <a
                            class="btn btn-secondary rounded-pill mb-2"
                            target="_blank"
                            :href="'/contact/?mls_number=' + selectedProperty"
                        >Request Info</a>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PropertyMap from "./PropertyMap";
import MapListing from "./MapListing";

export default {
    components: {
        "property-map": PropertyMap,
        "map-listing": MapListing,
    },
    props: ['omni','property_type','minPrice','maxPrice','beds','baths','sqft','acreage','status','waterfront','pool','themedir'],
    data() {
        return {
            selectedProperty: null,
            formData: {
                omni: this.omni ? this.omni : undefined,
                property_type: this.property_type ? this.property_type : undefined,
                minPrice: this.minPrice ? this.minPrice : undefined,
                maxPrice: this.maxPrice ? this.maxPrice : undefined,
                beds: this.beds ? this.beds : undefined,
                baths: this.baths ? this.baths : undefined,
                sqft: this.sqft ? this.sqft : undefined,
                acreage: this.acreage ? this.acreage : undefined,
                status: this.status ? this.status : ['Active','ActiveContingent','Active Contingent'],
                pool: this.acreage ? this.acreage : undefined,
                is_waterfront: this.is_waterfront ? this.is_waterfront : undefined,
                is_waterview: this.is_waterview ? this.is_waterview : undefined,
                open_houses: this.open_houses ? this.open_houses : undefined,
            },
            data: {
                "type": "FeatureCollection",
                "features": []
            },
            mapReady: true,
            property: {},
        };
    },

    mounted(){
        this.submitForm();
    },

    methods: {
        submitForm(event) {
            this.mapReady = false
            if (event) {
                event.preventDefault();
            }

            let vm = this;
            let route = "?q=search";
            this.data.features = []

            Object.keys(this.formData).forEach((key) => {
                if (vm.formData[key] != undefined && vm.formData[key] != "") {
                    if (Array.isArray(vm.formData[key])) {
                        route += "&" + key + "=" + vm.formData[key].join('|');
                    } else {
                        route += "&" + key + "=" + vm.formData[key];
                    }
                }
            });

            console.log(route)

            fetch("/wp-json/kerigansolutions/v1/mapsearch/" + route).then(r => r.json())
                .then((response) => {
                    console.log(response)
                    vm.data = response
                    vm.$emit('points loaded')
                    vm.mapReady = true
                });
        },
        openProperty(payload) {
            this.selectedProperty = Number(payload.properties.mls)
        },

    },
};
</script>
<style lang="scss" scoped>
.property-view {
    max-width: 100%;

    @media screen and (min-width: 768px){
        width: 300px;
    }
}
</style>
