var render = function render(){var _vm=this,_c=_vm._self._c;return _c('mapbox-map',{staticClass:"shadow border",attrs:{"access-token":_vm.accessToken,"map-style":_vm.mapStyle,"center":_vm.coordinates,"attributionControl":false,"zoom":_vm.zoom},on:{"mb-created":_vm.onMapLoaded}},[_c('mapbox-navigation-control',{attrs:{"position":"top-right"}}),_vm._v(" "),_c('mapbox-image',{attrs:{"src":_vm.host + '/wp-content/themes/98/img/map-pin-RESI-active.png',"id":"hut"}}),_vm._v(" "),(_vm.dataReady)?_c('mapbox-cluster',{attrs:{"data":_vm.pins,"clusterMaxZoom":12,"clustersPaint":{
            'circle-color': '#C41230',
            'circle-stroke-color': '#FFFFFF',
            'circle-stroke-width': 4,
            'circle-radius': 30
        },"clusterCountPaint":{
            'text-color': '#FFF'
        },"clusterCountLayout":{
            'text-field': [ 'get', 'point_count_abbreviated' ]
        },"unclusteredPointLayerType":"symbol","unclusteredPointLayout":{
            'text-field': [ 'get', 'price' ],
            'text-size': 12,
            'icon-image': 'hut',
            'icon-anchor': 'bottom',
            'text-offset': [0,-1.5]
        },"unclusteredPointPaint":{
            'text-color': '#000', 
            'text-halo-color': '#FFF',
            'text-halo-width': 3,
        }},on:{"mb-feature-click":_vm.propertyClick}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }