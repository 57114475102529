<template>
    <form class="quicksearch" method="get" action="/properties/" >
        <input type="hidden" name="q" value="search" >
        <div class="container no-gutter">
            <div class="row justify-content-end justify-content-xl-center">
                <div class="col-lg-auto">
                    <p class="search-form-label">PROPERTY <span>QUICK SEARCH</span></p>
                </div>
                <div class="col-sm-4 col-lg-3 flex-grow-1">
                    <smart-field
                        preview="Address, MLS, Area"
                    ></smart-field>
                </div>
                <div class="col-6 col-sm-4 col-lg-2">
                    <area-field class="w-100"></area-field>
                </div>
                <div class="col-6 col-sm-4 col-lg-2">
                    <property-type class="w-100"></property-type>
                </div>
                <div class="col-lg-2">
                    <div class="row no-gutter">
                        <button
                            @click="toggleAdvanced"
                            type="button"
                            class="btn btn-primary dropdown-toggle col-6"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            >Filter</button>
                        <button type="submit" class="btn btn-danger col-6" >Search</button>
                    </div>
                </div>
                
            </div>
        </div>
        <div v-if="advancedSearch" id="advanced-menu" class="advanced-menu">
            <div class="container-wide">
                <div class="row">
                    <div class="col-md-4 col-lg-6">

                        <div class="row">
                            <div class="col-6 col-md-12 col-lg-6">
                                <min-price-field
                                    class="mb-4"
                                >
                                </min-price-field>
                            </div>
                            <div class="col-6 col-md-12 col-lg-6">
                                <max-price-field
                                    class="mb-4"
                                ></max-price-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-md-12 col-lg-6">
                                <bedrooms-field
                                    class="mb-4"
                                ></bedrooms-field>
                            </div>
                            <div class="col-6 col-md-12 col-lg-6">
                                <bathrooms-field
                                    class="mb-4"
                                ></bathrooms-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-md-12 col-lg-6">
                                <sqft-field
                                    class="mb-4"
                                ></sqft-field>
                            </div>
                            <div class="col-6 col-md-12 col-lg-6">
                                <acreage-field
                                    class="mb-4"
                                ></acreage-field>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-6">

                        <status-field
                            class="mb-6"
                        ></status-field>

                        <details-field
                            class="mb-6"
                        ></details-field>

                    </div>
                </div>
            </div>
        </div>
        <input name="page" value="1" type="hidden" >
    </form>
</template>

<script>
    export default {
        
        data() {
            return {
                advancedSearch: false,
            }
        },

        methods: {
            toggleAdvanced(){
                this.advancedSearch = !this.advancedSearch;
            },
        }
    }
</script>