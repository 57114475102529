<template>
    <div class="mini-listing-container text-center" >
        <div class="bg-white" v-if="moduleReady">
            <div 
                class="embed-responsive embed-responsive-16by9 main-image relative bg-cover"
                :style="'background: url(' + data.preferred_photo + ') center no-repeat;'"
            >
                <span
                    v-if="data.status == 'Sold' || data.status == 'Closed'"
                    class="status-flag sold"
                >Sold on {{ soldDate }}<br>
                for ${{ soldPrice }}</span>

                <span
                    v-if="data.status == 'Pending'"
                    class="status-flag pending"
                >SALE PENDING</span>

                <span
                    v-if="data.status == 'Active Under Contract' || data.status == 'Under Contract'"
                    class="status-flag contingent">TAKING BACKUP OFFERS</span>

                <span
                    v-if="data.has_open_houses == 1 && data.status == 'Active'"
                    class="status-flag open-house"
                >OPEN HOUSE</span>
            </div>

            <div class="feat-prop-info">
                <div class="feat-prop-section" >
                    <span class="addr1">{{ data.street_num }} {{ data.street_name }}</span>
                    <br><span class="city">{{ data.city }}</span>, <span
                class="state">{{ data.state }}</span><br>
                    <span style="font-size:12px;">{{ data.property_type }}</span>
                </div>

                <div class="feat-prop-section price">
                    <p v-if="data.list_price > 0"><span class="price">${{ data.list_price.toLocaleString() }}</span></p>
                    <p v-else-if="data.list_price > 0"><span class="price">${{ data.monthly_rent.toLocaleString() }}</span></p>
                </div>

                <div class="feat-prop-section">
                    <div v-if="data.bedrooms > 0 || data.baths > 0" class="row pb-2 justify-content-center">
                        <div class="col-3">
                            <span class="icon"><img :src="themedir + '/img/rooms.svg'" alt="rooms" class="img-responsive lazy"></span>
                            <span class="beds-num icon-data">{{ data.bedrooms }}</span>
                            <span class="icon-label">ROOMS</span>
                        </div>
                        <div class="col-3 text-center">
                            <span class="icon"><img :src="themedir + '/img/baths.svg'" alt="bathrooms"
                            class="img-responsive lazy"></span>
                            <span class="baths-num icon-data">{{ data.baths_full }} / {{ data.baths_half }}</span>
                            <span class="icon-label">BATHS</span>
                        </div>
                        <div class="col-3 text-center">
                            <span class="icon"><img :src="themedir + '/img/sqft.svg'" alt="sqft"
                                                    class="img-responsive lazy"></span>
                            <span class="sqft-num icon-data">{{ data.sqft_hc }}</span>
                            <span class="icon-label">SQFT</span>
                        </div>
                    </div>
                    <div v-else class="row pb-2 justify-content-center">
                        <div class="col-4 text-center">
                            <span class="icon"><img :src="themedir + '/img/lotsize.svg'" alt="lot size" class="img-responsive lazy"></span>
                            <span
                                class="lot-dim-num icon-data">{{ data.lot_dimensions }}</span>
                            <span class="icon-label">LOT SIZE</span>
                        </div>
                        <div class="col-4 text-center">
                            <span class="icon"><img :src="themedir + '/img/acres.svg'" alt="acres" class="img-responsive lazy"></span>
                            <span class="acres-num icon-data">&nbsp;{{ data.acreage }}&nbsp;</span>
                            <span class="icon-label">ACRES</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="feat-prop-section text-center">
                <span class="mlsnum">MLS# {{ data.rets_listing_id }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import ListingPhoto from './ListingPhoto'
export default {
    components: {
        ListingPhoto
    },
    props: {
        mls: {
            type: Number,
            required: true,
        },
        themedir: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            moduleReady: false,
            preferred_photo: "",
            full_address: "",
            property_type: "",
            price: "",
            data: {},
        };
    },

	computed: {
		address () {
			if(this.data.property_type == 'Condominium') {
				return this.data.subdivision +
				(this.data.unit_num != '' ? ', <span class="inline-block">' + this.data.unit_num + '</span>' : '')
			} else {
				return this.data.street_num + ' ' +
				this.data.street_name + ' ' +
				(this.data.street_suffix != null ? this.data.street_suffix : '') +
				(this.data.unit_num != null ? ', <span class="inline-block">' + this.data.unit_num + '</span>' : '')
			}
		},
		isNew () {
			return this.threedaysago < this.listed
		},
		threedaysago () {
			let threedaysago = new Date()
			return threedaysago.setDate(threedaysago.getDate() - 3)
		},
		listed () {
			return Date.parse(this.data.date_listed)
		},
		soldDate () {
			if(this.data.sold_date) {
				let sold = this.data.sold_date.split("-")
				return sold[1] + '/' + sold[2] + '/' + sold[0]
			}
		},
		soldPrice () {
			if(this.data.sold_price) {
				return Math.round(this.data.sold_price).toLocaleString()
			}
		},
	},

    watch: {
        mls: function (newValue, oldValue) {
            if (newValue != "") {
                this.getListing();
            }
        },
    },

    created() {
        this.getListing();
    },

    methods: {
        getListing() {
            let listingTile = this;

			fetch("/wp-json/kerigansolutions/v1/listing?mls=" + this.mls).then(r => r.json())
				.then((response) => {
					console.log(response.data)
                    listingTile.data = response.data;
                    listingTile.moduleReady = true;
                });
        },
    },
};
</script>
