<template>
    <form class="form form-inline d-inline-block" method="get" >
        <input type="hidden" name="q" value="search" >
        <input v-if="searchTerms.omni" type="hidden" name="omni" :value="searchTerms.omni" >
        <input v-if="searchTerms.region && !hideArea" type="hidden" name="area" :value="searchTerms.region" >
        <input v-if="searchTerms.property_type && !hideType" type="hidden" name="propertyType" :value="searchTerms.property_type" >
        <input v-if="searchTerms.minPrice" type="hidden" name="minPrice" :value="searchTerms.minPrice" >
        <input v-if="searchTerms.maxPrice" type="hidden" name="maxPrice" :value="searchTerms.maxPrice" >
        <input v-if="searchTerms.sqft" type="hidden" name="sqft" :value="searchTerms.sqft" >
        <input v-if="searchTerms.acreage" type="hidden" name="acreage" :value="searchTerms.acreage" >
        <input v-if="searchTerms.details" type="hidden" name="details" :value="searchTerms.details" >
        <input v-if="searchTerms.beds" type="hidden" name="beds" :value="searchTerms.beds" >
        <input v-if="searchTerms.baths" type="hidden" name="baths" :value="searchTerms.baths" >
        <input v-if="searchTerms.is_waterfront" type="hidden" name="waterfront" :value="searchTerms.is_waterfront" >
        <input v-if="searchTerms.is_waterview" type="hidden" name="waterview" :value="searchTerms.is_waterview" >
        <input v-if="searchTerms.open_houses" type="hidden" name="foreclosure" :value="searchTerms.open_houses" >
        <input v-if="searchTerms.page" type="hidden" name="page" :value="searchTerms.page" >
        <div v-if="searchTerms.status">
            <input 
                v-for="(status, index) in searchTerms.status" 
                type="hidden" 
                :name="'status[' + index + ']'" 
                :key="status" 
                :value="status" >
        </div>
        <select name="sort" 
            v-model="selected" 
            class="custom-select" 
            style="width:auto;" >
            <option value="list_price|desc">Price - high to low</option>
			<option value="list_price|asc">Price - low to high</option>
            <option value="date_listed|desc">Date Listed </option>
            <option value="date_modified|desc">Date Modified </option>
            <option value="sold_date|desc">Date Sold </option>
        </select><button type="submit" class="btn btn-info" >Sort</button>    
    </form>
</template>

<script>
    export default {
        props: {
            fieldValue: {
                type: String,
                default: 'date_modified|desc'
            },
            searchTerms: {
                type: Object,
                default: () => {}
            },
            hideArea: {
                type: Boolean,
                default: false
            },
            hideType: {
                type: Boolean,
                default: false
            }
        },

        data () {
            return {
                selected: this.fieldValue
            }
        }
    }
</script>
