<template>
    <div>
        <div class="mb-2"><label class="font-weight-bold">Property Status</label></div>

        <label class="custom-control custom-checkbox d-flex justify-start mr-4">
            <input type="checkbox" name="status[]" value="Active" class="custom-control-input" v-model="active">
            <span class="custom-control-label">Active</span>
        </label>

        <label class="custom-control custom-checkbox d-flex justify-start mr-4">
            <input type="checkbox" name="status[]" value="Sold/Closed" class="custom-control-input" v-model="sold">
            <span class="custom-control-label">Sold</span>
        </label>

        <label class="custom-control custom-checkbox d-flex justify-start mr-4">
            <input type="checkbox" name="status[]" value="Contingent" class="custom-control-input" v-model="contingent">
            <span class="custom-control-label">Contingent</span>
        </label>

        <!-- <label class="d-none custom-control custom-checkbox d-flex justify-start mr-4">
            <input type="checkbox" name="status[]" value="Pending" class="custom-control-input" v-model="pending">
            <span class="custom-control-label">Pending</span>
        </label> -->
    </div>
</template>

<script>
    export default {
        props: {
            searchTerms: {
                type: Object,
                default: () => {}
            }
        },

        data () {
            return {
                active: true,
                sold: false,
                pending: true,
                contingent: true
            }
        },

        mounted () {
            this.active = this.searchTerms.includes('Active');
            this.sold = this.searchTerms.includes('Sold');
            this.pending = this.searchTerms.includes('Pending');
            this.contingent = this.searchTerms.includes('Contingent');
        }
    }
</script>