<template>
    <div>
        <select 
            name="property_type" 
            v-model="selected" 
            class="prop-type-input custom-select w-100" >
            <option value="">All Types</option>
            <option value="AllHomes">All Homes</option>
            <option value="AllLand">All Vacant Land</option>
            <option value="Detached Single Family">Single Family Homes</option>
            <option value="MultiUnit">Condos/Townhouses/Multi</option>
            <option value="Commercial">Commercial</option>
        </select>
    </div>
</template>

<script>
    export default {
        props: {
            fieldValue: {
                type: String,
                default: ''
            }
        },
        data () {
            return {
                selected: this.fieldValue
            }
        }
    }
</script>